
import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () =>
{
    return (
        <ReactLoading type='spinningBubbles' color='#fff' height={'10%'} width={'10%'} />
    )
}

export default Loading