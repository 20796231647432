import React from 'react'
import '../globalStyle.css';

const ProductionPieceContainer = () =>
{
    return (
        <section id='hero' style={{ backgroundImage: `url(./img/backgrounds/assistance.jpeg)`, top: 'center', }} className="d-flex align-items-center justify-content-center">
            <div className="container" data-aos="fade-up">
                <div className="row login-inner justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 assistanceContainer" data-aos="fade-up">
                        <div className="icon-box box-mobile">
                            <div className="col-xl-12 col-md-4 col-lg-8 welcome-container py-3">
                                <h1 className='bvn-phrase'>Le Guide de production de pièces</h1>
                                <h2 className="plate-form text-secondary">est en cours de Développement, Vous serez informé de l'évolution</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductionPieceContainer