import React, { useContext, useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function CustomSeparator({ breadcrumbs, onClickGoTo})
{
    const [tplBreadCrumbsItems, setTplBreadCrumbsItems] = useState([]);

    const tplBreadCrumbsItem = (item) =>
    {
        return (
            <Link underline="hover" key={item.key} color="inherit" style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => onClickGoTo(item)} >{item.title} </Link>
        )
    }

    useEffect(() =>
    {
        let _ = [];
        breadcrumbs.map((_item) =>
        {
            _.push(tplBreadCrumbsItem(_item))

        })
        setTplBreadCrumbsItems(_)
    }, [breadcrumbs])

    return (
        <Stack spacing={2}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {tplBreadCrumbsItems}
            </Breadcrumbs>
        </Stack>
    );
}