import React, {useCallback, useEffect, useState} from "react";
import "./header.css";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { TfiWrite } from "react-icons/tfi";
import { MdFollowTheSigns, MdOutlineHelp } from "react-icons/md";
import "./assistance/assistanceContainer.css";
import { BsFillCalendar2CheckFill } from "react-icons/bs";
import { SiFiles } from "react-icons/si";
import { FaFileAudio } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { RiFileSearchFill } from "react-icons/ri";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaUserLock } from "react-icons/fa";
import {toast} from "react-toastify";
import { Button, Modal } from 'react-bootstrap';

const Header = () => {
    const [navButton, setNavButton] = useState("");
    const [showModal, setShowModal] = useState(false);
    let location = useLocation();
    const navigate = useNavigate();
    let locationPath = location.pathname;
    const declarerPath = ["/declare", "/search", "/signup", "/success"];
    const suivrePath = [
        "/login",
        "/readcode",
        "/newcode",
        "/suivrehome",
        "/piece",
        "/courierdecla",
        "/couriersini",
        "/allmailContainer",
        "/history",
    ];
    const assisterPath = [
        "/assistance",
        "/faq",
        "/rdv",
        "/rdvsuccess",
        "/guide",
        "/tutorial",
    ];
    let data = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (declarerPath.includes(locationPath)) setNavButton("declaration");
        else if (suivrePath.includes(locationPath)) setNavButton("suivi");
        else if (assisterPath.includes(locationPath)) setNavButton("assistance");
    }, [locationPath, navButton]);

    // Fonction de déconnexion
    const logout = () => {
        localStorage.clear();
        setShowModal(false);
        navigate("/");
    };

    // Ouvrir le modal
    const openModal = () => setShowModal(true);

    // Fermer le modal
    const closeModal = () => setShowModal(false);

    return (
        <>
            <header id="header" className="fixed-top">
                <div className="container-fluid d-flex items-center justify-between">
                    <div>
                        <Link style={{ textDecoration: "none" }} to="/">
                          <img src="./logo.png" className="img-design" alt="sinistre_care"/>
                        </Link>
                    </div>

                    {locationPath === "/" ? (
                        <span className="get-started-btn">SINISTRE CARE</span>
                    ) : (
                        <div className="get-started-btn">
                            <Link to="/search" type="button" className={`btn bts-sm ${navButton === "declaration" ? "btn-warning" : "btn-outline-light"} mx-1`}
                                title="Déclarer un décès">
                                <span className="flex justify-center items-center">
                                    <TfiWrite size={22} /> <label className="d-none d-md-inline m-1">Déclaration</label>
                                </span>
                            </Link>
                            <Link to={(data !== null) ? 'suivrehome' : 'login'} type="button" className={`btn bts-sm ${navButton === "suivi" ? "btn-warning" : "btn-outline-light"} mx-1`} title="Suivre mon dossier">
                                <span className="flex justify-center items-center">
                                    <MdFollowTheSigns size={22} /> <label className="d-none d-md-inline m-1">Suivi</label>
                                </span>
                            </Link>
                            <Link to="/assistance" type="button" className={`btn bts-sm ${navButton === "assistance" ? "btn-warning" : "btn-outline-light"} mx-1`}
                                title="Me faire assister">
                                <span className="flex justify-center items-center">
                                    <MdOutlineHelp size={22} /> <label className="d-none d-md-inline m-1">Assistance</label>
                                </span>
                            </Link>
                        </div>
                    )}
                    {data !== null ? (
                        <div className="get-started-btn">
                            {/*{navButton === "suivi" && locationPath !== "/login" && locationPath !== "/readcode" && locationPath !== "/newcode" ? (*/}
                                <Link to="/allmailContainer">
                                    <button type="button" className={(data.data.courriers.nouveaux > 0) ? 'btn bts-sm btn-outline-light mx-1 relative inline-flex -right-5' : 'btn bts-sm btn-outline-light mx-1'} title="Courriers">
                                        <BsFillEnvelopeFill size={30} className="d-none d-md-block"/>
                                        <BsFillEnvelopeFill size={22} className="d-block d-md-none"/>
                                    </button>
                                    {(data.data.courriers.nouveaux > 0) ? (
                                        <label className="no-underline relative inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-6 dark:border-gray-900">
                                            {data.data.courriers.nouveaux}
                                        </label>
                                    ) : ("")}
                                </Link>
                            {/*) : null}*/}
                            <button type="button" className="btn bts-sm btn-outline-warning mx-1" title="Déconnexion" onClick={openModal}>
                                <FaUserLock size={30} className="d-none d-md-block"/>
                                <FaUserLock size={22} className="d-block d-md-none"/>
                            </button>

                            {/* Modal de confirmation */}
                            <Modal show={showModal} onHide={closeModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation de déconnexion</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Êtes-vous sûr de vouloir vous déconnecter ?
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={closeModal}>
                                        Annuler
                                    </Button>
                                    <Button variant="primary" onClick={logout}>
                                        Déconnexion
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    ) : null}
                </div>
            </header>

            {navButton === "assistance" && locationPath !== "/assistance" && locationPath !== "/" && (
                <ul className="container nav nav-pills fixed-top" style={{ marginTop: "6rem", color: "#ffffff" }}>
                    <li className="nav-item">
                        <Link to="/faq" type="button" className={`btn bts-sm ${locationPath === "/faq" ? "btn-warning" : "btn-outline-light"} m-2`}
                            style={{ boxShadow: "2px 2px 2px 2px #e3e6e7" }}>
                            <span className="flex justify-center items-center">
                                <RiFileSearchFill size={20} /> <label className="d-none d-md-inline m-1">Foire aux questions</label>
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/rdv" type="button" className={`btn bts-sm ${locationPath === "/rdv" ? "btn-warning" : "btn-outline-light"} m-2`}
                            style={{ boxShadow: "2px 2px 2px 2px #e3e6e7" }}>
                            <span className="flex justify-center items-center">
                                <BsFillCalendar2CheckFill size={20} /> <label className="d-none d-md-inline m-1">Prendre rdv</label>
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/guide" type="button" className={`btn bts-sm ${locationPath === "/guide" ? "btn-warning" : "btn-outline-light"} m-2`}
                            style={{ boxShadow: "2px 2px 2px 2px #e3e6e7" }}>
                            <span className="flex justify-center items-center">
                                <SiFiles size={20} /> <label className="d-none d-md-inline m-1">Guide de production de pièces</label>
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/assistance" type="button" className={`btn bts-sm ${navButton === "declaration" ? "btn-warning" : "btn-outline-light"} m-2`}
                            style={{ boxShadow: "2px 2px 2px 2px #e3e6e7" }}>
                            <span className="flex justify-center items-center">
                              <BiArrowBack size={20} /> <label className="d-none d-md-inline m-1">Retour</label>
                            </span>
                        </Link>
                    </li>
                </ul>
            )}
        </>
    );
};

export default Header;
