import React, { useEffect, useMemo, useState } from 'react'
import './history.css';
import { FaHistory } from "react-icons/fa";
import qs from "qs";
import global_variables from "../../controllers/globalVar";
import axios from "axios";
import AlertContent from "../../alertContent/AlertContent";
import { Link } from "react-router-dom";
import { BsFillCalendarCheckFill, BsFillEnvelopeFill, BsFillEnvelopeOpenFill } from "react-icons/bs";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Chronogramme from '../home/Chronogramme.js'
import ChronogrammeMobile from "../home/chronogrammeMobile";

const HistoryContainer = () =>
{
    const [value, setValue] = React.useState(0);
    const [histories, setHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    let result = JSON.parse(localStorage.getItem('user'));
    let numDeclaration = useMemo(() => [result.data.numero_declaration], [result.data.numero_declaration])

    useEffect(() =>
    {
        setLoading(true)
        var data = qs.stringify({
            'numero_declaration': `${numDeclaration}`
        });
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/show-declaration-history`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios(config).then((response) =>
        {
            setLoading(false)
            if (response.data.status === true)
            {
                setHistories(response.data.data.historiques);
            }
        })
            .catch((error) =>
            {
                setLoading(false)
                console.log(error);
            });
    }, [])

    const handleChange = (event, newValue) =>
    {
        setValue(newValue);
    };
    //userInfo
    // let data = JSON.parse(localStorage.getItem('user'));
    if (loading) return <AlertContent />

    return (
        <section style={{ backgroundImage: `url(./img/backgrounds/suivi.jpg)`, top: 'center', }} id="hero" className="in-containerlog">
            <div className='container-fluid'>
                <div className='row'>
                    <div className="fixed-element col-md-5 col-xl-3 d-none d-md-block">
                        <Chronogramme />
                    </div>
                    <div className="d-block d-md-none">
                        <ChronogrammeMobile />
                    </div>
                    <div className="scrollable-element col-sm-6 col-md-7 col-xl-9 mt-8 px-md-5">
                        <div className="font-sans antialiased bg-grey-lightest mt-sm-0 mt-md-5 mt-lg-5">
                            <div className="w-full bg-grey-lightest lightest-container">
                                <div className="container lightest-inner">
                                    <div className="card w-full mt-md-5 rounded shadow lightest-shadow">
                                        <div className="card-body lighest-main-login">
                                            <div className="timeline timeline-left">
                                                <div className="timeline-item">
                                                    <div className="time-show first flex justify-between">
                                                        <button type="button" className="flex bg-primary p-2 text-xl waves-effect waves-light w-lg">
                                                            <FaHistory className='m-1' /> Historique des actions sur la déclaration N° {numDeclaration}
                                                        </button>
                                                        <div className='declare-btn flex justify-center items-center float-end d-none d-md-block'>
                                                            <Link to='/suivrehome' type="button" className="return-btn flex recherche-btn justify-center items-center p-2 text-xl font-medium  rounded" style={{ textDecoration: 'none' }}>
                                                                <AiOutlineArrowLeft className="mr-2" /> Retour
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="timeline-item">
                                                    <ul>
                                                        {histories.map((history, index) =>
                                                        {
                                                            return (
                                                                <li key={index}>
                                                                    <label className="arrow"></label>
                                                                    <span>{history.libelle}</span>
                                                                    <div className="content">
                                                                        <h3>{history.description}</h3>
                                                                        <p className="flex"><BsFillCalendarCheckFill className='m-1' /> Effectuée le {history.date_creation}</p>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <div className='declare-btn flex justify-center items-center'>
                                                <Link to='/suivrehome' type="button" className="return-btn flex recherche-btn justify-center items-center py-2 px-3 text-2xl font-medium  rounded" style={{ textDecoration: 'none' }}>
                                                    <AiOutlineArrowLeft className="mr-2" /> Retour
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default HistoryContainer
