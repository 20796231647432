import React from 'react'
import FaqStep from "./FaqStep";

const FaqContainer = () => {
    return (
        <section id='hero' style={{ backgroundImage: `url(./img/backgrounds/faq.jpg)`, top: 'center', }} className="d-flex align-items-center justify-content-center">
            <div className="container" data-aos="fade-up">
                <div className="row login-inner justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 assistanceContainer" data-aos="fade-up">
                        <FaqStep />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FaqContainer