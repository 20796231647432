import React, { useContext, useState } from 'react'
import { AppContext } from '../../../context/ContextApi.js'
import Viewer from './ContextViewer/Viewer.js';

const FaqContainer = () => {
    const { faqstep } = useContext(AppContext)
    const renderCurrentSelection = () => {
        return <Viewer />
    }

    return (
        <div className="container-fluid custom-sm" data-aos="fade-up" >
            <div className="row login-inner justify-center" data-aos="zoom-in" data-aos-delay="250">
                <div className="col-12 login-container-mobile" data-aos="fade-up">
                    <div className="font-sans antialiased bg-grey-lightest">
                        <div className="w-full bg-grey-lightest lightest-container">
                            <div className="container lightest-inner">
                                <div className="card w-full rounded shadow lightest-shadow">
                                    {renderCurrentSelection()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqContainer