const steps = [
    {
        selector: '[data-tour="step-1"]',
        content: "text 1"
    },
    {
        selector: '[data-tour="step-2"]',
        content: "text 2"
    },
    {
        selector: '[data-tour="step-3"]',
        content: "text 3"
    }
];

export default steps;