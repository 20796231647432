// import React, { useState } from "react";
// import { Viewer, Worker } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import { GrView } from "react-icons/gr";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import CloseIcon from "@mui/icons-material/Close";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";

// const style = {
//   margin: 12,
//   height: 700,
//   width: 800,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };

// const PdfTest = () => {
//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const [selectedImage, setSelectedImage] = useState(null);

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     const imageUrl = URL.createObjectURL(file);
//     setSelectedImage(imageUrl);
//   };
//   const handleUpload = () => {
//     // Perform the upload logic here
//     if (selectedImage) {
//       // You can send the selectedImage file to the server or perform any other processing
//       console.log("Selected image:", selectedImage);
//     }
//   };
// const newplugin = defaultLayoutPlugin();

// const [pdfFile, setPDFfile] = useState(null);
// const [viewPdf, setViewPdf] = useState(null);
// const [viewImg, setViewImg] = useState(null);
// const [open, setOpen] = React.useState(false);
// const handleOpen = () => setOpen(true);
// const handleClose = () => setOpen(false);

// const fileType = ["application/pdf"];
// const otherType = [
//   "application/image",
//   "application/jpg",
//   "application/png",
//   "application/tiff",
//   "application/bmp",
// ];
// const handleChange = (e) => {
//   let selectedfile = e.target.files[0];
//   if (selectedfile) {
//     if (selectedfile && fileType.includes(selectedfile.type)) {
//       let reader = new FileReader();
//       reader.readAsDataURL(selectedfile);
//       reader.onload = () => {
//         setPDFfile(reader.result);
//       };
//     } else if (selectedfile && otherType.includes(selectedfile.type)) {
//       const imageUrl = URL.createObjectURL(selectedfile);
//       console.log("lien image", selectedfile);
//       setPDFfile(imageUrl);
//     } else {
//       setPDFfile(null);
//     }
//   } else {
//     console.log("No selected file");
//   }
// };

// const handleSubmit2 = (e) => {
//   e.preventDefault();
//   if (pdfFile !== null) {
//     setViewPdf(pdfFile);
//     setOpen(true);
//   } else {
//     setViewPdf(null);
//   }
// };
// return (
//   <div style={{ marginTop: "10rem" }}>
//     <div>
//       <input type="file" onChange={handleImageChange} />
//       <button onClick={handleUpload}>Upload</button>
//     </div>
//     <Button variant="primary" onClick={handleShow}>
//       Launch demo modal
//     </Button>

//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Modal heading</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {" "}
//         <img src={selectedImage} alt="Preview" />
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//         <Button variant="primary" onClick={handleClose}>
//           Save Changes
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   </div>
// <div className="container" style={{ marginTop: "10rem" }}>
//   <form onSubmit={handleSubmit2}>
//     <input type="file" className="form-control" onChange={handleChange} />
//     <button type="submit" className="btn btn-success">
//       <GrView />
//     </button>
//   </form>
//   {viewPdf ? (
//     // <div className="pdf-container">
//     //   <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
//     //     {viewPdf && (
//     //       <>
//     //         <Viewer fileUrl={viewPdf} pugins={[newplugin]} />
//     //       </>
//     //     )}
//     //     {!viewPdf && <>No PDF</>}
//     //   </Worker>
//     // </div>

//     <div>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <Typography id="modal-modal-title" variant="h6" component="h2">
//               Visualisation
//             </Typography>
//             <CloseIcon
//               onClick={handleClose}
//               style={{ cursor: "pointer" }}
//             />
//           </div>
//           <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
//             {viewPdf && (
//               <>
//                 <Viewer fileUrl={viewPdf} pugins={[newplugin]} />
//               </>
//             )}
//             {!viewPdf && <>No PDF</>}
//           </Worker>
//         </Box>
//       </Modal>
//     </div>
//   ) : (
//     <div>
//       <img src={viewImg} alt="Preview" />;
//     </div>
//     // <div>
//     //   return <img src={viewImg} alt="Preview" />;
//     // </div>
//   )}
// </div>
//   );
// };

// export default PdfTest;
import React, { useState } from "react";

function ImageUpload() {
  const [imageFields, setImageFields] = useState([
    { id: 0, file: null, previewVisible: false },
  ]);
  const [previewImages, setPreviewImages] = useState([]);

  const handleImageChange = (event, id) => {
    const files = event.target.files;
    const newImageFields = [...imageFields];
    const file = files[0];

    newImageFields.forEach((field) => {
      if (field.id === id) {
        field.file = file;
      }
    });

    setImageFields(newImageFields);

    const reader = new FileReader();
    reader.onloadend = () => {
      const newPreviewImages = [...previewImages];
      newPreviewImages[id] = reader.result;
      setPreviewImages(newPreviewImages);
    };
    reader.readAsDataURL(file);
  };

  const handleTogglePreview = (id) => {
    const newImageFields = [...imageFields];
    newImageFields.forEach((field) => {
      if (field.id === id) {
        field.previewVisible = !field.previewVisible;
      }
    });

    setImageFields(newImageFields);
  };

  const handleAddField = () => {
    const newId = imageFields.length;
    const newField = { id: newId, file: null, previewVisible: false };
    setImageFields([...imageFields, newField]);
    setPreviewImages([...previewImages, null]);
  };

  const handleDeleteField = (id) => {
    const newImageFields = imageFields.filter((field) => field.id !== id);
    const newPreviewImages = previewImages.filter(
      (image, index) => index !== id
    );
    setImageFields(newImageFields);
    setPreviewImages(newPreviewImages);
  };

  return (
    <div style={{ marginTop: "10rem" }}>
      {imageFields.map((field) => (
        <div key={field.id} className="image-field">
          <input
            type="file"
            accept="image/*"
            onChange={(event) => handleImageChange(event, field.id)}
          />
          <button onClick={() => handleTogglePreview(field.id)}>
            {field.previewVisible ? "Hide Preview" : "Show Preview"}
          </button>
          {field.previewVisible && previewImages[field.id] && (
            <img src={previewImages[field.id]} alt={`Preview ${field.id}`} />
          )}
          <button onClick={() => handleDeleteField(field.id)}>Delete</button>
        </div>
      ))}
      <button onClick={handleAddField}>Add</button>
    </div>
  );
}

export default ImageUpload;

// import React, { useState } from "react";

// function ImageUpload() {
//   const [selectedImages, setSelectedImages] = useState([null, null, null]);
//   const [previewVisible, setPreviewVisible] = useState([false, false, false]);

//   const handleImageChange = (event, index) => {
//     const file = event.target.files[0];
//     const newSelectedImages = [...selectedImages];
//     newSelectedImages[index] = file;
//     setSelectedImages(newSelectedImages);
//   };

//   const handleTogglePreview = (index) => {
//     const newPreviewVisible = [...previewVisible];
//     newPreviewVisible[index] = !newPreviewVisible[index];
//     setPreviewVisible(newPreviewVisible);
//   };

//   return (
//     <div style={{ marginTop: "10rem" }}>
//       <div>
//         <input
//           type="file"
//           accept="image/*"
//           onChange={(event) => handleImageChange(event, 0)}
//         />
//         {selectedImages[0] && (
//           <div>
//             {previewVisible[0] && (
//               <img
//                 src={URL.createObjectURL(selectedImages[0])}
//                 alt="Preview 1"
//               />
//             )}
//             <button onClick={() => handleTogglePreview(0)}>
//               {previewVisible[0] ? "Hide Preview" : "Show Preview"}
//             </button>
//           </div>
//         )}
//       </div>
//       <div>
//         <input
//           type="file"
//           accept="image/*"
//           onChange={(event) => handleImageChange(event, 1)}
//         />
//         {selectedImages[1] && (
//           <div>
//             {previewVisible[1] && (
//               <img
//                 src={URL.createObjectURL(selectedImages[1])}
//                 alt="Preview 2"
//               />
//             )}
//             <button onClick={() => handleTogglePreview(1)}>
//               {previewVisible[1] ? "Hide Preview" : "Show Preview"}
//             </button>
//           </div>
//         )}
//       </div>
//       <div>
//         <input
//           type="file"
//           accept="image/*"
//           onChange={(event) => handleImageChange(event, 2)}
//         />
//         {selectedImages[2] && (
//           <div>
//             {previewVisible[2] && (
//               <img
//                 src={URL.createObjectURL(selectedImages[2])}
//                 alt="Preview 3"
//               />
//             )}
//             <button onClick={() => handleTogglePreview(2)}>
//               {previewVisible[2] ? "Hide Preview" : "Show Preview"}
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default ImageUpload;
