import React from 'react'
import './login.css'
import NumberForm from './NumberForm.js';
import './login.css'

const GeneCode = () => {
    return (
        <section style={{ backgroundImage: `url(./img/backgrounds/suivi.jpg)`, top: 'center', }} id="hero" className="d-flex align-items-center justify-content-center">
            <div className="container-fluid  custom-sm" data-aos="fade-up">
                <div className="row login-inner justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 login-container-mobile" data-aos="fade-up">
                        <NumberForm />
                    </div>
                </div>
            </div>
        </section>



    )
}

export default GeneCode