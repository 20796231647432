import React, { useContext } from "react";
import Souscripteur from "./Souscripteur.js";
import Defunt from "./Defunt.js";
import Declarant from "./Declarant.js";
import Beneficiaires from "./Beneficiaires.js";
import Confirm from "./Confirm.js";
import Stepper from "./Stepper.js";
import { AppContext } from "../../../context/ContextApi.js";

const SignupMulti = () => {
    const { step, steps } = useContext(AppContext);
    const renderCurrentSelection = () => {
        switch (step) {
            case 1:
                return <Souscripteur />;
            case 2:
                return <Defunt />;
            case 3:
                return <Declarant />;
            case 4:
                return <Beneficiaires />;
            case 5:
                return <Confirm />;
            default:
                return null;
        }
    };

    return (
        <div className="font-sans antialiased bg-grey-lightest declaration-sinistre-mobile">
            <div className="w-full bg-grey-lightest">
                <div className="container-fluid lightest-inner">
                    <div className="card w-full rounded shadow lightest-shadow">
                        <div className="card-header py-3 text-3xl text-center renseignement">DECLARATION DE SINISTRE</div>
                        <div className="card-body lightest-main">
                            <div className="container horizontal stepper-container-horizontal"><Stepper /></div>
                            <div className="py-3 login-container-mobile">{renderCurrentSelection()}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupMulti;
