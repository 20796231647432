import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../../context/ContextApi';
import axios from 'axios';
import qs from 'qs';
import './rdv.css'
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineCheck } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../../loading/Loading.js";
import global_variables from "../../controllers/globalVar";

const RdvRecap = () =>
{
    const { rdvPreviousStep, rdv ,setRdv} = useContext(AppContext)
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate()


    const handleValidation = () =>
    {
        // console.log('this is the rendez-vous',rdv)
        setLoading(true)
        // submit the form data to backend or do something else with the data
        let data = qs.stringify({
            'nom': `${rdv.rdvNom}`,
            'prenoms': `${rdv.rdvPrenom}`,
            'objet': `${rdv.rdvMotif}`,
            'date': `${rdv.rdvDate}`,
            'heure': `${rdv.rdvHeure}`,
            'contact': `${rdv.rdvTelephone}`,
            'residence':`${rdv.rdvResidence}`
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'https://servo.integralewebservice.com/api/create-courier',
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/create-courier`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios.request(config)
            .then((response) =>
            {
                setLoading(false);
                console.log(response.data);
                toast.success("Votre demande de RDV a bien été enregistrée. Vous serez contacté par un Gestionnaire Clientèle pour la confirmation.", {
                    position: "top-center",
                    autoClose: 1000000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                setRdv({
                    rdvNom: '',
                    rdvPrenom: '',
                    rdvTelephone: '',
                    rdvMotif: '',
                    rdvHeure: '',
                    rdvDate: '',
                    rdvResidence: '',
                });
                navigate('/assistance');
            })
            .catch((error) =>
            {
                setLoading(false)
                toast.error("Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.", {
                    position: "top-center",
                    autoClose: 1000000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                console.log(error);
            });
    }

    const handleBack = (values) =>
    {
        rdvPreviousStep(values)
    }

    return (
        <div className="card-body lightest-main">
            <div className="py-3 login-container-mobile">
                {/* <div className="sm:flex justify-evenly align-center px-8 md:flex justify-center align-center  lg:flex justify-evenly align-center ">
                    <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                        <div className="flex pb-3">
                            <dt className="mb-1  text-4xl font-serif font-medium text-gray-500 md:text-3xl dark:text-gray-400">
                                NOM :
                            </dt>
                            <dd className=" text-2xl text-white font-mono ml-4 font-semibold"> {rdv.rdvNom}</dd>
                        </div>
                        <div className="flex  py-3">
                            <dt className="mb-1 text-4xl font-serif font-medium text-gray-500 md:text-2xl dark:text-gray-400">
                                TELEPHONE :
                            </dt>
                            <dd className=" text-2xl text-white font-mono ml-4 font-semibold">{rdv.rdvTelephone}</dd>
                        </div>
                        <div className="flex  py-3">
                            <dt className="mb-1 text-4xl font-serif font-medium text-gray-500 md:text-2xl dark:text-gray-400">
                                L'HEURE :
                            </dt>
                            <dd className=" text-2xl text-white font-mono ml-4 font-semibold">{rdv.rdvHeure}</dd>
                        </div>

                    </dl>
                    <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">

                        <div className="flex  pb-3">
                            <dt className="mb-1 text-4xl font-serif font-medium text-gray-500 md:text-3xl dark:text-gray-400">
                                PRENOMS :
                            </dt>
                            <dd className=" text-2xl text-white font-mono ml-4 font-semibold"> {rdv.rdvPrenom} </dd>
                        </div>
                        <div className="flex  py-3">
                            <dt className="mb-1 text-4xl font-serif font-medium text-gray-500 md:text-3xl dark:text-gray-400">
                                MOTIF:
                            </dt>
                            <dd className="text-2xl text-white font-mono ml-4 font-semibold">{rdv.rdvMotif}</dd>
                        </div>
                        <div className="flex  py-3">
                            <dt className="mb-1 text-4xl font-serif font-medium text-gray-500 md:text-3xl dark:text-gray-400">
                                RESIDENCE:
                            </dt>
                            <dd className="text-2xl text-white font-mono ml-4 font-semibold">{rdv.rdvResidence}</dd>
                        </div>

                    </dl>
                    <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                        <div className="flex  pb-3">
                            <dt className="mb-1  text-4xl font-serif font-medium text-gray-500 md:text-3xl dark:text-gray-400">
                                DATE:
                            </dt>
                            <dd className=" text-2xl text-white font-mono ml-4 font-semibold">{rdv.rdvDate}</dd>
                        </div>

                    </dl>
                </div> */}
                <div className="py-3 login-container-mobile">
                    <div className="row flex cutom-box">
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block  mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvNom">NOM :</label>
                            <span className=" text-xl  text-white font-mono font-semibold"> {rdv.rdvNom}</span>
                        </div>
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block  mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvPrenom">PRENOMS :</label>
                            <span className=" text-xl text-white font-mono  font-semibold"> {rdv.rdvPrenom} </span>
                        </div>
                    {/*</div>*/}
                    {/*<div className="flex cutom-box">*/}
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block  mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvResidence">RESIDENCE :</label>
                            <span className="text-xl text-white font-mono  font-semibold">{rdv.rdvResidence}</span>
                        </div>
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block  mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvTelephone">TELEPHONE : </label>
                            <span className=" text-xl text-white font-mono font-semibold">{rdv.rdvTelephone}</span>
                        </div>
                    {/*</div>*/}
                    {/*<div className="flex cutom-box">*/}
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvDate">DATE :</label>
                            <span className=" text-xl text-white font-mono  font-semibold">{rdv.rdvDate}</span>
                        </div>
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvHeure">HEURE : </label>
                            <span className=" text-xl text-white font-mono  font-semibold">{rdv.rdvHeure}</span>
                        </div>
                    {/*</div>*/}
                    {/*<div className="flex cutom-box">*/}
                        <div className="col-12 p-2 flex items-center custom-with">
                            <label className="block mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvMotif">MOTIF : </label>
                            <span className="text-xl text-white font-mono font-semibold">{rdv.rdvMotif}</span>
                        </div>
                    </div>
                </div>

                {loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></div> : <div className="flex justify-center mt-3">
                    <div className="flex justify-center align-center info-sous-btn">
                        <Link onClick={() => handleBack('')} type="button" className="w-1/2 info-sous-return-btn flex justify-center items-center return-btn flex recherche-btn mx-2 px-3 py-2 w-100 text-2xl font-medium rounded" style={{ textDecoration: 'none' }}>
                            <AiOutlineArrowLeft className="mr-2" /> Retour
                        </Link>
                        <button type='submit' onClick={handleValidation} className="flex info-sous-recherche recherche-btn justify-center items-center mx-2 px-3 py-2 w-100 text-2xl font-medium text-white rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400">
                            <AiOutlineCheck className="mr-2" /> Valider
                        </button>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default RdvRecap