import React, { useEffect, useMemo, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import AlertContent from "../../../alertContent/AlertContent";
import "../faq.css";

export default function FaqItemsContentViewer({ itemsContentView }) {
    let rowsArray = [];

    itemsContentView.forEach((each, index) => {
        rowsArray.push({ title: `${each.title}`, content: `${each.content}` })
    })

    if (rowsArray.length === 0) return <AlertContent />

    return (
        <div className="accordion accordion-flush" id="accordionFlushExample">
            {rowsArray.map((item, index) => {
                return (
                    <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={`flush-heading-${index}`}>
                            <button className="accordion-button collapsed text-2xl" type="button"
                                    data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${index}`}
                                    aria-expanded="false" aria-controls={`#flush-collapse-${index}`}>
                                <h3>{item.title}</h3>
                            </button>
                        </h2>
                        <div id={`flush-collapse-${index}`} className="accordion-collapse collapse"
                             aria-labelledby={`flush-heading-${index}`} data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <div dangerouslySetInnerHTML={{__html: item.content}} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}