import React, { useContext } from 'react'
import { AppContext } from '../../../context/ContextApi.js';
import RdvForm from './RdvForm.js';
import RdvRecap from './RdvRecap.js';

const RdvMulti = () =>
{
    const { step, rdvSteps } = useContext(AppContext)
    const renderCurrentSelection = () =>
    {
        switch (step)
        {
            case 1:
                return <RdvForm />
            case 2:
                return <RdvRecap />
            default:
                return null;
        }
    }

    return (
        <div className="card w-full rounded shadow">
            <div className="card-header py-3 text-3xl text-center">DEMANDE DE RENDEZ-VOUS</div>
            <div className="card-body lightest-main">
                <div className="py-3 login-container-mobile">
                    {renderCurrentSelection()}
                </div>
            </div>
        </div>

    )
}

export default RdvMulti