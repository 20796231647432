import global_variables from "../components/controllers/globalVar.js";

const axios_base_url = global_variables().SINISTRE_CARE_WEBSERVICE;

const axios_config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${axios_base_url}/list-categories-faq`,
    headers: {}
};


export default { axios_config, axios_base_url };
