import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../context/ContextApi.js";
import SouscripteurInfo from '../form/SouscripteurInfo.js'
import { FaUserTie } from 'react-icons/fa';

export default function Type()
{
    const { selectedOption, setSelectedOption, selectedItem, setSelectedItem, successful, setSuccessful, message, setMessage, showAlert, alert } = useContext(AppContext)
    const handleCheckbox = (e) =>
    {
        setSelectedOption(e.target.value)
    }

    return (
        <>
            <div className="w-full type-container">
                <div className="font-sans antialiased bg-grey-lightest">
                    <div className="type-container-light w-full bg-grey-lightest">
                        <div className="container lightest-inner">
                            <div className="card w-full rounded shadow ightest-shadow">
                                <div className="card-header flex justify-center items-center py-3 text-3xl text-center info-souscripteur">
                                    {/*<div className="flex justify-center align-center info-sous-header">*/}
                                    <FaUserTie size={25} className="m-1" /> INFOS CLIENT
                                    {/*</div>*/}
                                </div>
                                <div className="card-body p-4 info-souscripteur-box">
                                    {/* <div className="m-2">
                                        <form className="form-type flex">
                                            <input name="souscriber" onChange={(e) => handleCheckbox(e)} type="radio" value="1" className="btn-check" id="souscriber_id" autoComplete="off" />
                                            <label className="btn btn-outline-light m-2" htmlFor="souscriber_id">Info Souscripteur</label>
                                            <input name="souscriber" onChange={(e) => handleCheckbox(e)} type="radio" value="2" className="btn-check" id="police_id" autoComplete="off" />
                                            <label className="btn btn-outline-light m-2" htmlFor="police_id">Numero Police</label>
                                            <input name="souscriber" onChange={(e) => handleCheckbox(e)} type="radio" value="3" className="btn-check" id="client_id" autoComplete="off" />
                                            <label className="btn btn-outline-light m-2" htmlFor="client_id">Numero Client</label>
                                        </form>
                                    </div> */}
                                    {selectedOption === "1" && <SouscripteurInfo />}
                                    {/* {selectedOption === "2" && <PoliceForm />}
                                    {selectedOption === "3" && <NumClient />} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
