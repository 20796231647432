import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import AppContextApi from './context/ContextApi';
import { TourProvider } from "@reactour/tour";
import steps from "./steps";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <TourProvider steps={steps}>
                <AppContextApi>
                    <App />
                </AppContextApi>
            </TourProvider>
        </BrowserRouter>
    </React.StrictMode>
);

