import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: 'transparent', }}>
            <ReactLoading type='spinningBubbles' color='rgba(0, 0, 0, 0.4)' height={'30%'} width={'30%'} />
        </div>
    )
}

export default Loading