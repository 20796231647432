import React from 'react';
import './rdv.css'
import RdvMulti from './RdvMulti.js';

const RdvContainer = () =>
{
    return (
        <section id='hero' style={{ backgroundImage: `url(./img/backgrounds/assistance.jpeg)`, top: 'center', }} className="d-flex align-items-center justify-content-center">
            <div className="container mt-16" data-aos="fade-up">
                <div className="row login-inner justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 assistanceContainer" data-aos="fade-up">
                        <RdvMulti />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RdvContainer