import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {AiFillEye, AiFillFileText, AiOutlineArrowLeft} from 'react-icons/ai';
import {BsFillEnvelopeFill, BsFillEnvelopeOpenFill} from "react-icons/bs";

export default function SinistreCourier() {
    const [value, setValue] = React.useState(0);
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('user')))

    //getting data from localstorage
    let courrierSini = JSON.parse(localStorage.getItem('courrierSini'));
    let data2 = JSON.parse(localStorage.getItem('user'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className='card-body py-3 rounded-lg'>
                <h4 className="text-4xl p-2 text-white font-mono leading-tight">COURRIERS DU SINISTRE</h4>
                <div className="flex justify-around">
                    <div className="flex  py-1 cutom-box">
                        <h4 className="mb-1  custom-with-label text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">Numero declaration :</h4>
                        <dd className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">{data2.data.numero_declaration}</dd>
                    </div>
                    <div className="flex  py-1 cutom-box">
                        <h4 className="mb-1  custom-with-label text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">Declarant :</h4>
                        <dd className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">{data2.data.declarant}</dd>
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-12 table-responsive">
                    <table className="table table-sm table-nowrap min-w-full leading-normal">
                        <thead>
                            <tr>
                                <th className="p-3 text-left text-gray-600 uppercase">Rerence</th>
                                <th className="p-3 text-left text-gray-600 uppercase">Libellé</th>
                                <th className="p-3 text-left text-gray-600 uppercase">Destinataire</th>
                                <th className="p-3 text-left text-gray-600 uppercase">Date d'émission</th>
                                <th className="p-3 text-left text-gray-600 uppercase">Actions</th>
                            </tr>
                        </thead>
                        <tbody className='card-body tbody-mobile text-left'>
                            {courrierSini?.data.courriers.map((courie, index) => {
                                return (
                                    <tr key={index}>
                                        <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>{courie.reference}</td>
                                        <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>{courie.libelle}</td>
                                        <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>{courie.destinataire}</td>
                                        <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>{courie.date_creation}</td>
                                        <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>
                                            <a href={courie.url} target="_blank" type="button" title='courriers ' className="no-underline relative text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg  px-3 py-2  dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                                                <span className="flex justify-center items-center">
                                                    {(courie.date_ouverture.length === 0) ? <BsFillEnvelopeFill className='m-1' color='yellow' /> : <BsFillEnvelopeOpenFill className='m-1' color='green' />}
                                                    {(courie.date_ouverture.length === 0) ? "Ouvrir" : "Afficher"}
                                                </span>
                                            </a>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='declare-btn flex justify-center items-center'>
                <Link to='/suivrehome' type="button" className="return-btn flex recherche-btn justify-center items-center py-2 px-5 text-2xl font-medium rounded" style={{ textDecoration: 'none' }}>
                    <AiOutlineArrowLeft className="mr-2" /> Retour
                </Link>
            </div>
        </>
    );
}