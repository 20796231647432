import React, { useState, useEffect } from 'react';
import { BsFileEarmarkLock2 } from 'react-icons/bs';
import { GiCheckMark } from 'react-icons/gi';
import { RxCross2 } from 'react-icons/rx';
import './chronogramme.css';

const Chronogramme = () =>
{
    const [myData, setMyData] = useState({});
    const [updateContextStoreData, setUpdateContextStoreData] = useState(false);

    useEffect(() =>
    {
        let storeData = localStorage.getItem('user');
        if (storeData !== null)
        {
            storeData = JSON.parse(storeData);
            setMyData(storeData);
        }
    }, [updateContextStoreData])
    return (
        <div className="flex flex-col md:grid grid-cols-12 text-gray-50 mt-4">
            <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                    <div className="h-full w-6 flex items-center justify-center">
                        <div className={`h-full w-1 ${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '} pointer-events-none`} />
                    </div>
                    <div className={`w-12 h-12 absolute top-10 -ml-3 p-2 rounded-full ${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} shadow text-center`}>
                        {myData.data?.chronogramme.declaration.status == 'validé' ? <GiCheckMark size={30} color='#fff' /> : <BsFileEarmarkLock2 size={30} color='#fff' />}
                    </div>
                </div>
                <div className={`${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} col-start-4 col-end-12 p-3 my-3 -ml-3 rounded-xl shadow-md w-full`}>
                    <h3 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.declaration.title}</h3>
                    <p className="flex leading-tight text-start">{myData.data?.chronogramme.declaration.details.status == true ? <GiCheckMark size={20} color='#ffffff' /> : <RxCross2 size={20} color='#ef4444d9' /> } {myData.data?.chronogramme.declaration.details.libelle}</p>
                </div>
            </div>
            <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                    <div className="h-full w-6 flex items-center justify-center">
                        <div className={`h-full w-1 ${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '} pointer-events-none`} />
                    </div>
                    <div className={`w-12 h-12 absolute top-10 -ml-3 p-2 rounded-full ${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} shadow text-center`}>
                        {myData.data?.chronogramme.analyse.status == 'validé' ? <GiCheckMark size={30} color='#fff' /> : <BsFileEarmarkLock2 size={30} color='#fff' />}
                    </div>
                </div>
                <div className={`${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} col-start-4 col-end-12 p-3 my-3 -ml-3 rounded-xl shadow-md w-full`}>
                    <h3 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.analyse.title}</h3>
                    {myData.data?.chronogramme.declaration.status == 'validé' ?
                        <p className="flex leading-tight text-start">{myData.data?.chronogramme.analyse.status == 'validé' ? <GiCheckMark size={20} color='#ffffff' /> : (myData.data?.chronogramme.analyse.details.pieces.status === true ? <GiCheckMark size={20} color='#22c55ed9' /> : <RxCross2 size={20} color='#ef4444d9' />)} {myData.data?.chronogramme.analyse.details.pieces.libelle}</p>
                        : <p className="flex leading-tight text-start">En attente de validation de la déclaration</p>
                    }
                    {myData.data?.chronogramme.analyse.details.polices.map((police, index) => {
                        return (
                            <p key={index} className="flex leading-tight text-start">{myData.data?.chronogramme.analyse.status == 'validé' ? <GiCheckMark size={20} color='#ffffff' /> : (police.status === true ? <GiCheckMark size={20} color='#22c55ed9' /> : <RxCross2 size={20} color='#ef4444d9' />)} {police.libelle}</p>
                        )
                    })}
                </div>
            </div>
            <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                    <div className="h-full w-6 flex items-center justify-center">
                        <div className={`h-full w-1 ${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '} pointer-events-none`} />
                    </div>
                    <div className={`w-12 h-12 absolute top-10 -ml-3 p-2 rounded-full ${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} shadow text-center`}>
                        {myData.data?.chronogramme.reglement.status == 'validé' ? <GiCheckMark size={30} color='#fff' /> : <BsFileEarmarkLock2 size={30} color='#fff' />}
                    </div>
                </div>
                <div className={`${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} col-start-4 col-end-12 p-3 my-3 -ml-3 rounded-xl shadow-md w-full`}>
                    <h3 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.reglement.title}</h3>
                    {myData.data?.chronogramme.reglement.details.polices.map((police,index) =>
                    {
                        return (
                            <p key={index} className="flex leading-tight text-start">{myData.data?.chronogramme.reglement.status == 'validé' ? <GiCheckMark size={20} color='#ffffff' /> : (police.status === true ? <GiCheckMark size={20} color='#22c55ed9' /> : (police.status === false ? <RxCross2 size={20} color='#ef4444d9' /> : ''))} {police.libelle}</p>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default Chronogramme;

