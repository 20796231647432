import React, { useContext, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { AppContext } from "../../../context/ContextApi";
import { Link, useNavigate, redirect } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import global_variables from "../../controllers/globalVar.js";
import Loader from "../../loading/Loading.js";

const validationSchema = Yup.object().shape({});
const Confirm = () => {
    const {individuel, individuelNextStep, individuelPreviousStep, fileName, fileName2, fileName3, isCheck, selectedOption, setSelectedOption, selectedItem,
        setSelectedItem, declareAcceuil, setDeclareAcceuil, successful, setSuccessful, message, setMessage, showAlert, alert, researchResult, setSearchResult,
        dynamicFields, staticFields, staticSelectedCheckboxes, dynamicSelectedCheckboxes, staticBox, dynamicBox, numPolices, userDate, handleEmpty,
    } = useContext(AppContext);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState("NONE/NONE");
    const navigate = useNavigate();

    const date = new Date(individuel.sousDateNaissance);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const formattedDate = date.toLocaleDateString("fr-FR", options);

    const date2 = new Date(userDate);
    const options2 = { year: "numeric", month: "numeric", day: "numeric" };
    const formattedDate2 = date2.toLocaleDateString("fr-FR", options2);

    const date3 = new Date(individuel.defDateDeces);
    const options3 = { year: "numeric", month: "numeric", day: "numeric" };
    const formattedDate3 = date3.toLocaleDateString("fr-FR", options3);

    const handleSubmit = (values) => {
        individuelNextStep(values);
    };
    const handleBack = (values) => {
        individuelPreviousStep(values);
    };

    const handleValidation = useCallback(
        (values) => {
        setLoading(true);
        //implementing today's date params
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();
        today = yyyy + "-" + mm + "-" + dd;

        // //implementing polices params
        let userPolices = [];
        isCheck.forEach(function(item, i) {
            userPolices[i] = item;
        });

        // //implementing pieces_assure params
        let test = [];
        test.push("1");
        let nature = "scanne";
        test.push(`${nature}`);
        let img = staticFields.file;
        test.push(`${img}`);
        let pieces_assure = [];
        pieces_assure.push(test);

        // //implementing declarant contact
        let declaContact = [];
        declaContact.push(`${values.declaContact}`, `${values.autreContact}`);

        let pieces_beneficiaire = [];

        dynamicFields.forEach((each, index) => {
            pieces_beneficiaire.push([
                `${each.newFileId}`,
                `${each.dropdownValue}`,
                null,
                "scanne",
                `${each.file}`,
            ]);
        });

        const postme = {
            date_declaration: today,
            polices: numPolices,
            nom_client: values.sousNom,
            prenoms_client: values.sousPrenom,
            assure: values.assure,
            date_naissance: values.defDateNaissance,
            date_deces: values.defDateDeces,
            lieu_deces: values.defLieuDeces,
            cause_deces: values.cause_deces,
            civilite_declarant: values.civilite,
            declarant: values.declaNom + " " + values.declaPrenom,
            qualite_declarant: values.qualite_declarant,
            contact_declarant: declaContact,
            email_declarant: [values.declaAdressElectro],
            adresse_declarant: values.declaAdressePost,
            pieces_assure: pieces_assure,
            pieces_beneficiaire: pieces_beneficiaire,
        };

        var config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${
              global_variables().SINISTRE_CARE_WEBSERVICE
            }/declaration-sinistre`,
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(postme),
        };

        axios(config)
            .then(function(response) {
                localStorage.setItem("numero", JSON.stringify(response.data));
                      toast.success("Félicitations! Enregistrement réussi.", {
                            position: "top-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                      });
                handleEmpty(navigate, "success");
            })
            .catch(function(error) {
                  setLoading(false);
                  // showAlert("Une erreur  s'est produite", 'error')
                  toast.error(
                        "Désolé! Le service que vous tentez de joindre ne réponds pas veuillez réessayer plus tard.",
                        {
                          position: "top-center",
                          autoClose: 10000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        }
                  );
                  console.log(error);
            });
        },
        [selectedOption, selectedItem, setSearchResult, researchResult]
    );

    return (
            <Formik validationSchema={validationSchema} initialValues={individuel} onSubmit={handleSubmit}>
                {({ values }) => (
                    <Form>
                        <h3 className="section-title section-title-mobile">RESUME DES INFORMATIONS SAISIES</h3>
                        <div className="row flex cutom-box">
                            <dl className="col-md-6 custom-with text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 sous-resume">
                                <div className="card-header py-2 text-black text-2xl text-center  border-grey-lighter">SOUSCRIPTEUR & DEFUNT</div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        SOUSCRIPTEUR :
                                        <span className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.sousNom} {individuel.sousPrenom}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        DATE DE NAISSANCE : <span
                                        className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">{formattedDate}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        CONTACT : <span
                                        className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.sousContactTel}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        ADRESSE POSTALE : <span
                                        className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.sousAdressePostale}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        DEFUNT :
                                        <span className=" text-white ml-2 custom-with-label text-xl mt-2  leading-3">{individuel.assure}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className=" custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        DATE DE NAISSANCE : <span
                                        className="text-white ml-2 custom-with-label text-xl  mt-2 leading-3">{formattedDate2}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        LIEU DE NAISSANCE : <span
                                        className=" text-white ml-2 custom-with-label text-xl mt-2  leading-3">{individuel.defLieuNaissance}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        PROFESSION : <span
                                        className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.defProfession}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className=" custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        DATE DE DECES : <span
                                        className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">{formattedDate3}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className=" custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        LIEU DE DECES : <span
                                        className=" text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.defLieuDeces}</span>
                                    </dt>
                                </div>
                            </dl>
                            <dl className="col-md-6 custom-with text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 sous-defunt">
                                <div
                                    className="card-header py-2 text-black custom-with-label text-2xl border-b text-center font-sans font-normal border-b  border-grey-lighter">
                                    DECLARANT & DOCUMENTS JOINTS
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">
                                        DECLARANT : <span
                                        className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.declaNom} {individuel.declaPrenom}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl text-gray-500 md:text-xl dark:text-gray-400">
                                        LIEN DE PARENTE : <span
                                        className="text-white ml-2 custom-with-label  text-xl mt-2 leading-3">{individuel.qualite_declarant}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl text-gray-500 md:text-xl dark:text-gray-400">
                                        CONTACT : <span
                                        className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.declaContact}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl text-gray-500 md:text-xl dark:text-gray-400">
                                        AUTRE CONTACT : <span
                                        className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.autreContact}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl text-gray-500 md:text-xl dark:text-gray-400">
                                        EMAIL : <span
                                        className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.declaAdressElectro}</span>
                                    </dt>
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className=" custom-with-label text-xl text-gray-500 md:text-xl dark:text-gray-400">
                                        ADRESSE POSTALE : <span
                                        className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">{individuel.declaAdressePost}</span>
                                    </dt>
                                </div>
                                <div className="flex flex-wrap p-2 cutom-box">
                                    <dt className="fcustom-with-label text-xl  text-gray-500 md:text-xl dark:text-gray-400">POLICES :</dt>
                                    {numPolices.map((itemChecked, index) => {
                                        return (
                                            <div key={index}>
                                                <dd key={index}
                                                    className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">
                                                    {itemChecked} {(itemChecked.length < 2 || index === (itemChecked.length - 1)) ? "" : ","}
                                                </dd>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="flex p-2 cutom-box">
                                    <dt className="custom-with-label text-xl text-gray-500 md:text-xl dark:text-gray-400">
                                        Document requis : <span
                                        className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">Acte de décès</span>
                                    </dt>
                                </div>
                                {dynamicFields.length > 0 ? (
                                    <div className="flex p-2 cutom-box">
                                        <dt className="custom-with-label text-xl text-gray-500 md:text-xl dark:text-gray-400">
                                            Autres documents :
                                            {dynamicFields.map((dynamicField, index) => {
                                                return (
                                                    <div key={index}>
                                                        <span
                                                            className="text-white ml-2 custom-with-label text-xl mt-2 leading-3">
                                                            {dynamicField.dropdownValue}{" "}
                                                            {(dynamicFields.length < 2 || index === (dynamicFields.length - 1)) ? "" : ", "}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </dt>
                                    </div>
                                ) : null}
                            </dl>
                        </div>

                        {loading ? (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <Loader/>
                            </div>
                        ) : (
                            <div className="flex justify-between align-center btn-container souscripteur-btn-mobile">
                                <div className="w-1/2 mr-1 flex justify-center">
                                    <button onClick={() => handleBack("")} type="button"
                                            className="return-btn flex recherche-btn justify-center items-center py-2 px-3 text-2xl font-medium rounded">
                                    <AiOutlineArrowLeft className="mr-2"/> Précédent
                                    </button>
                                </div>
                                <div className="w-1/2 flex justify-center">
                                    <button onClick={() => handleValidation(values)} type="submit"
                                            className="login-btn flex recherche-btn justify-center items-center py-2 px-3 text-2xl font-medium text-black rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400">
                                        Enregistrer <BiSave className="ml-2"/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
    );
};

export default Confirm;
