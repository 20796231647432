import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../../context/ContextApi';
import './rdv.css'
import { Link, } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineCheck } from "react-icons/ai";


const RdvForm = () =>
{
    const { rdvNextStep, rdv, setRdv } = useContext(AppContext)

    const handleInputChange = (event) =>
    {
        const { name, value } = event.target;
        setRdv((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        var today = new Date().toISOString().split('T')[0];
        document.getElementsByName("rdvDate")[0].setAttribute('min', today);
    }, [])

    const handleSubmit = (event) =>
    {
        event.preventDefault();
        if(!rdv.rdvNom || !rdv.rdvPrenom || !rdv.rdvMotif || !rdv.rdvDate || !rdv.rdvHeure || !rdv.rdvTelephone ){
            toast.error("Veuillez remplir tous les  champs.", {
                position: "top-center",
                autoClose: 1000000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }else{
            rdvNextStep(rdv);
        }

    };
    return (
        <div className="py-3 login-container-mobile">
            <div className="row flex cutom-box">
                <div className="col-md-6 p-2 custom-with">
                    <label className="block text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvNom">NOM <strong style={{ color: '#cc992d' }}>*</strong></label>
                    <input className="appearance-none border inner-box-input  rounded text-xl font-mono w-full p-2  text-grey-darker" id="rdvNom" type="text"
                        name="rdvNom" value={rdv.rdvNom} onChange={handleInputChange} placeholder="Votre Nom"  required />
                    {/*<p className="mt-2 text-red-600 dark:text-red-500"> {errors.nomFam && touched.nomFam ? errors.nomFam : null}</p>*/}
                </div>
                <div className="col-md-6 p-2 custom-with">
                    <label className="block text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvPrenom">PRENOMS <strong style={{ color: '#cc992d' }}>*</strong></label>
                    <input className="appearance-none border inner-box-input rounded text-xl  font-mono w-full  p-2  text-grey-darker" id="rdvPrenom" type="text"
                        name="rdvPrenom" value={rdv.rdvPrenom} onChange={handleInputChange} placeholder="Votre Prenoms" required />
                    {/*<p className="mt-2 text-red-600 dark:text-red-500">{errors.prenoms && touched.prenoms ? errors.prenoms : null}</p>*/}
                </div>
            {/*</div>*/}
            {/*<div className="flex cutom-box">*/}
                <div className="col-md-6 p-2 custom-with">
                    <label className="block text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvResidence">LIEU DE RESIDENCE <strong style={{ color: '#cc992d' }}>*</strong></label>
                    <input className="appearance-none border inner-box-input rounded text-xl  font-mono w-full  p-2  text-grey-darker" id="rdvResidence" type="text"
                        name="rdvResidence" value={rdv.rdvResidence} onChange={handleInputChange} placeholder="Lieu de résidence...." required />
                    {/*<p className="mt-2 text-red-600 dark:text-red-500">{errors.prenoms && touched.prenoms ? errors.prenoms : null}</p>*/}
                </div>
                <div className="col-md-6 p-2 custom-with">
                    <label className="block text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvTelephone">NUMERO DE TELEPHONE <strong style={{ color: '#cc992d' }}>*</strong></label>
                    <input className="appearance-none border inner-box-input rounded  text-xl font-mono w-full p-2 text-grey-darker" id="rdvTelephone" type="phone"
                        name="rdvTelephone" value={rdv.rdvTelephone} onChange={handleInputChange} placeholder="Votre Numero de Telephone" required/>
                    {/*<p className="mt-2 text-red-600 dark:text-red-500">{errors.dateNais && touched.dateNais ? errors.dateNais : null}</p>*/}
                </div>
            {/*</div>*/}
            {/*<div className="flex cutom-box">*/}
                <div className="col-6 p-2 custom-with">
                    <label className="block text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvDate">DATE <strong style={{ color: '#cc992d' }}>*</strong></label>
                    <input className="appearance-none border inner-box-input   rounded text-xl font-mono w-full p-2  text-grey-darker" id="rdvDate" type="date"  name="rdvDate" value={rdv.rdvDate} onChange={handleInputChange} placeholder="Quelle est votre disponibilité?" required />
                    {/*<p className="mt-2 text-red-600 dark:text-red-500"> {errors.nomFam && touched.nomFam ? errors.nomFam : null}</p>*/}
                </div>
                <div className="col-6 p-2 custom-with">
                    <label className="block text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvHeure">HEURE <strong style={{ color: '#cc992d' }}>*</strong></label>
                    <input className="appearance-none border inner-box-input rounded text-xl  font-mono w-full  p-2  text-grey-darker" id="rdvHeure" type="time" min="09:00" max="18:00" required
                        name="rdvHeure" value={rdv.rdvHeure} onChange={handleInputChange} placeholder="l'heure...." />
                    {/*<p className="mt-2 text-red-600 dark:text-red-500">{errors.prenoms && touched.prenoms ? errors.prenoms : null}</p>*/}
                </div>
            {/*</div>*/}
            {/*<div className="flex cutom-box">*/}
                <div className="col-12 p-2 custom-with">
                    <label className="block text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvMotif">MOTIF DE DEMANDE <strong style={{ color: '#cc992d' }}>*</strong></label>
                    <textarea className="appearance-none border inner-box-input  rounded text-xl font-mono w-full p-2  text-grey-darker" name='rdvMotif' id="rdvMotif" type="text"
                        value={rdv.rdvMotif} onChange={handleInputChange} placeholder="VOTRE MOTIF DE DEMANDE"  required/>
                    {/*<p className="mt-2 text-red-600 dark:text-red-500"> {errors.nomFam && touched.nomFam ? errors.nomFam : null}</p>*/}
                </div>
            </div>
            <div className="flex justify-center mt-3">
                <div className="flex justify-center align-center info-sous-btn">
                    <Link to='/assistance' type="button" className="w-1/2 info-sous-return-btn flex justify-center items-center return-btn flex recherche-btn mx-2 px-3 py-2 w-100 text-2xl font-medium rounded" style={{ textDecoration: 'none' }}>
                        <AiOutlineArrowLeft className="mr-2" /> Retour
                    </Link>
                    <button onClick={(e) => handleSubmit(e)} className="flex info-sous-recherche recherche-btn justify-center items-center mx-2 px-3 py-2 w-100 text-2xl font-medium text-white rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400">
                    Continuer <AiOutlineArrowRight className="ml-2" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RdvForm