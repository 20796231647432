import axios from "axios";
import FormData from "form-data";
import React, { useContext, useState, useCallback } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./loginform.css";
import { CgPresentation } from "react-icons/cg";
import { AiOutlineArrowLeft, AiOutlineLogin } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import global_variables from "../../controllers/globalVar.js";
import Loader from "../../loading/Loading.js";

const validationSchema = Yup.object().shape({
  nouveauCode: Yup.string().required("Ce champ est obligatoire"),
});

const GeneForm = () => {
  const {
    individuel,
    individuelNextStep,
    individuelPreviousStep,
    fileName,
    fileName2,
    fileName3,
    isCheck,
    selectedOption,
    setSelectedOption,
    selectedItem,
    setSelectedItem,
    declareAcceuil,
    setDeclareAcceuil,
    successful,
    setSuccessful,
    message,
    setMessage,
    showAlert,
    alert,
    researchResult,
    setSearchResult,
  } = useContext(AppContext);
  const { corporate, corporateNextStep, corporatePreviousStep } = useContext(
    AppContext
  );
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("NONE/NONE");
  const navigate = useNavigate();
  const initialValues = {
    nouveauCode: "",
  };
  const handleSubmit = useCallback(
    (values) => {
      setLoading(true);
      var data = new FormData();
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${
          global_variables().SINISTRE_CARE_WEBSERVICE
        }/forget-declaration-number?contact_declarant=${values.nouveauCode}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios(config)
        .then(function(response) {
          console.log(response, "message");
          if (response.data.data.length === 0) {
            setLoading(false);
            toast.error(`Désolé!${response.data.error}`, {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            toast.success(`${response.data.data.message}`, {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            navigate("/login");
          }
        })
        .catch(function(error) {
          setLoading(false);
          toast.error(
            "Désolé! Le service que vous tentez de joindre ne répond pas veuillez réessayer plus tard.",
            {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          console.log(error);
        });
    },
    [selectedOption, selectedItem, setSearchResult, researchResult]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="font-sans antialiased bg-grey-lightest ">
            <div className="w-full bg-grey-lightest lightest-container">
              <div className="container mx-auto py-4 lightest-inner">
                <div className="card w-full lg:w-full mx-auto bg-white rounded shadow lightest-shadow">
                  <div className=" flex justify-center items-center card-header py-3  text-3xl text-center renseignement">
                    <CgPresentation size={60} color="#121b44" /> RENVOYER LE
                    NUMERO DE DECLARATION
                  </div>
                  <div className="card-body lighest-main-login">
                    <div className="flex justify-evenly my-3 items-center cutom-box">
                      <div className="w-full mx-2 flex-col justify-center align-center code-box cutom-with">
                        <Field
                          className="flex justify-around p-2 items-center text-center appearance-none border custom-with-label rounded text-xl font-mono w-full text-grey-darker"
                          name="nouveauCode"
                          id="nouveauCode"
                          type="text"
                          placeholder="Numéro de téléphone associé à la déclaration"
                        />
                        <p className="text-xl text-red-600 dark:text-red-500">
                          {errors.nouveauCode && touched.nouveauCode
                            ? errors.nouveauCode
                            : null}
                        </p>
                      </div>
                    </div>

                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <div className="flex  justify-center mb-5 btn-container souscripteur-btn-mobile">
                        <div className="flex justify-center align-center text-center police-btn-container">
                          <Link
                            to="/login"
                            type="button"
                            className="w-1/2 flex justify-center items-center login-btn-mobile return-btn recherche-btn mx-2 py-2 px-3 w-100 text-2xl font-medium rounded"
                            style={{ textDecoration: "none" }}
                          >
                            <AiOutlineArrowLeft className="mr-2" /> Retour
                          </Link>
                          <button
                            type="submit"
                            className="w-1/2 flex justify-center login-btn flex recherche-btn justify-center items-center mx-2 py-2 px-3 w-100 text-2xl font-medium text-black bg-amber-500  rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
                          >
                            Envoyer <AiOutlineLogin className="ml-2" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GeneForm;
