import React from "react";

const Checkbox = ({ id, type, name, handleClick, isChecked, dataId }) =>
{
  return (
    <>
      <input
        style={{ margin: "1rem", fontFamily: "helvetica" }}
        id={id}
        name={name}
        type={type}
        dataId={dataId}
        className="btn-check"
        onChange={handleClick}
        checked={isChecked}
        autoComplete="off"
      />

    </>
  );
};

export default Checkbox;
