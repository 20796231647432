import React, { useState, useEffect } from 'react';
import { BsFileEarmarkLock2 } from 'react-icons/bs';
import { GiCheckMark } from 'react-icons/gi';
import { RxCross2 } from 'react-icons/rx';
import './chronogramme.css';

const ChronogrammeMobile = () => {
    const [myData, setMyData] = useState({});
    const [updateContextStoreData, setUpdateContextStoreData] = useState(false);

    useEffect(() => {
        let storeData = localStorage.getItem('user');
        if (storeData !== null) {
            storeData = JSON.parse(storeData);
            setMyData(storeData);
        }
    }, [updateContextStoreData])
    return (
        <div className="container-fluid pt-5">
            <div className="row">
                <div className="col-lg-12">
                    <div className="horizontal-timeline">
                        <ul className="list-inline items">
                            <li className="list-inline-item items-list">
                                <div className={`${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} rounded mr-1`}>
                                    <div className={`event-date badge text-center ${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '}`}>
                                        {myData.data?.chronogramme.declaration.status == 'validé' ? <GiCheckMark size={15} color='#fff' /> : <BsFileEarmarkLock2 size={15} color='#fff' />} Déclaration
                                    </div>
                                    <p className="pt-2 text-light">{myData.data?.chronogramme.declaration.status == 'validé' ? "Validation de la déclaration effectuée" : "Validation de la déclaration en cours"}</p>
                                </div>

                                {/*<div className="bg-green-500 rounded mr-1">*/}
                                {/*    <div className="flex justify-center items-center event-date badge bg-green-500 text-center">*/}
                                {/*        <GiCheckMark size={15} color='#fff' /> Déclaration*/}
                                {/*    </div>*/}
                                {/*    <p className="pt-2 text-light">{myData.data?.chronogramme.declaration.details.date_creation}</p>*/}
                                {/*</div>*/}
                            </li>
                            <li className="list-inline-item items-list">
                                <div className={`${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} rounded mr-1`}>
                                    <div className={`event-date badge text-center ${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '}`}>
                                        {myData.data?.chronogramme.analyse.status == 'validé' ? <GiCheckMark size={15} color='#fff' /> : <BsFileEarmarkLock2 size={15} color='#fff' />} Analyse
                                    </div>
                                    <p className="pt-2 text-light">{myData.data?.chronogramme.declaration.status == 'validé' ? (myData.data?.chronogramme.analyse.status == 'validé' ? "Pièces requises transmises" : "Pièces requises en attente") : 'Analyse du sinistre en attente'}</p>
                                </div>
                            </li>
                            <li className="list-inline-item items-list">
                                <div className={`${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} rounded mr-1`}>
                                    <div className={`event-date badge text-center ${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '}`}>
                                        {myData.data?.chronogramme.reglement.status == 'validé' ? <GiCheckMark size={15} color='#fff' /> : <BsFileEarmarkLock2 size={15} color='#fff' />} Paiement
                                    </div>
                                    <p className="pt-2 text-light">{myData.data?.chronogramme.reglement.status == 'validé' ? "Paiement de sinistre effectué" : (myData.data?.chronogramme.reglement.status == 'en cours' ? "Paiement de sinistre en cours" : "Paiement de sinistre en attente")}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChronogrammeMobile;

